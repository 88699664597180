<template>
    <div class="Activities">
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in lunboList" :key="index">
                <img class="van-swipe-item-img" v-lazy="item.image" />
            </van-swipe-item>
        </van-swipe>
        <div class="Activities-main">
            <!-- Activities -->
            <div class="Activities-box w1330">
                <div class="Activities-title text-align b800">GROUP ACTIVITIES</div>
                <ul class="Activities-list flex flex-wrap">
                    <li class="Activities-list-item flex flex-cos align-center pointer" v-for="item in list"
                        :key="item.id" @click="$router.push(`/AcDetail?id=${item.id}`)">
                        <div class="list-item-cover">
                            <img :src="item.image">
                        </div>
                        <span class="list-item-title oneline f26 b800">{{ item.title }}</span>
                        <div class="list-item-intro twoline f26">
                            {{ item.detail }}
                        </div>
                    </li>
                </ul>
                <!-- 分页器 -->
                <pager @before="pageClick(-1)" @next="pageClick(1)"></pager>
            </div>
        </div>
    </div>
</template>
  
<script>
import pager from '../components/pager.vue'

export default {
    name: 'Activities',
    data() {
        return {
            lunboList: [],
            page: 1,
            limit: 12,
            list: []
        }
    },
    components: {
        pager
    },
    created() {
        this.getLunbo()
        this.getList()
    },
    methods: {
        //获取背景轮播
        async getLunbo() {
            var res = await this.$api.Get('index/advertisement?position=introduce&page=1&limit=99999')
            this.lunboList = res.data
        },
        //事件列表
        async getList() {
            var res = await this.$api.Get(`index/importantEvents?position=group&page=${this.page}&limit=${this.limit}`)
            this.list = res.data
        },
        async pageClick(step) {
            var idx = this.page + step
            if (idx < 1) {
                this.toast('没有更多数据')
            } else {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(255, 255, 255, 0.7)'
                });
                var res = await this.$api.Get(`index/importantEvents?position=group&page=${idx}&limit=${this.limit}`)
                if (res.data.length) {
                    this.page = idx
                    this.list = res.data
                    this.scroll()
                } else this.toast('没有更多数据')
                loading.close();
            }
        },
        toast(message) {
            this.$message({
                type: 'warning',
                message,
                offset: 100
            });
        },
        scroll() {
            var dis = document.querySelector('.Activities-box').offsetTop - document.querySelector('#navbar').clientHeight
            document.documentElement.scrollTop = dis
        }
    }
}
</script>
<style lang="less" scoped>
.van-swipe-item-img {
    display: block;
    width: 100%;
    height: calc(100vh - 71px);
    object-fit: cover;
}

.Activities-main {
    background-color: #e4e4e5;
}

.Activities-title {
    padding: 90px 0 130px;
    line-height: 1;
    font-size: 75px;
    color: #f96f2f;
}

.Activities-list-item {
    margin-right: 100px;
    width: 372px;
}

.Activities-list-item:nth-of-type(3n) {
    margin-right: 0;
}

.Activities-list-item:nth-of-type(n + 4) {
    margin-top: 50px;
}

.list-item-cover {
    width: 100%;
    height: 242px;
    box-shadow: 0px 12px 9px 0px rgba(6, 0, 1, 0.25);
    overflow: hidden;
}

.list-item-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s ease-in-out;
}

.Activities-list-item:hover {
    .list-item-cover img {
        transform: scale(1.12);
    }

    .list-item-title,
    .list-item-intro {
        color: #f96f2f;
    }
}

.list-item-title {
    margin: 30px 0 20px;
    color: #484848;
    transition: .3s ease-in-out;
    word-break: break-all;
}

.list-item-intro {
    line-height: 30px;
    color: #2a2a2a;
    word-break: break-all;
    transition: .3s ease-in-out;
}

@media screen and (max-width:750px) {
    .van-swipe-item-img {
        height: auto;
    }

    .Activities-box {
        width: 300px;
    }

    .Activities-title {
        padding: 35px 0 30px;
        font-size: 20px;
    }

    .Activities-list-item {
        width: 84px;
        margin-right: 24px;
    }

    .Activities-list-item:nth-of-type(n + 4) {
        margin-top: 24px;
    }

    .list-item-cover {
        width: 100%;
        height: 53px;
    }

    .list-item-title {
        margin: 10px 0 6px;
        font-size: 11px;
    }

    .list-item-intro {
        font-size: 8px;
        line-height: 1.3;
    }
}
</style>
